import { Route, Routes, Navigate  } from "react-router-dom";
import Audit from "./pages/Audit";
import LandingPage from "./pages/LandingPage";
import SignInPage from "./pages/SignInPage";
import EmployeeSummaryDashboard from "./pages/EmployeeSummaryDashboard";
import EmployeeDiary from "./pages/EmployeeDiary";
import EmployeeProfile from "./pages/EmployeeProfile";
import ManagerDashboard from "./pages/ManagerDashboard";
import ManagerProjectsDashboard from './pages/ManagerProjectsDashboard';
import UserSummaryDashboard from "./pages/UserSummaryDashboard";
import UserDiary from "./pages/UserDiary";
import UsersRequest from "./pages/UsersRequests"
import ManagerPayrollFullDetails from "./pages/ManagerPayrollFullDetails";
import TeamDashboard from "./pages/TeamDashboard";
import TeamMemberDashboard from "./pages/TeamMemberDashboard"
import React, { useMemo } from 'react';
import { ReactSession } from 'react-client-session';
import Settings from "./pages/Settings";
import PersonalInfo from "./pages/PersonalInfo";
import AdminTrolleyReport from "./pages/AdminTrolleyReport";
import AdminUpdateRate from "./pages/AdminUpdateRate";
import AdminAdditionalPayments from "./pages/AdminAdditionalPayments";
import AdditionalPayments from "./pages/AdditionalPayments";
import ManagerManualTimeApproval from "./pages/ManagerManualTimeApproval";
import AdminAnnouncements from "./pages/AdminAnnouncements";
import AdminWebTracker from "./pages/AdminWebTracker";
import UserCompliance from "./pages/UserCompliance";
import { post, put } from "./components/api/apiCalls"; 
import ExtraLoginModal from "./components/manager/managerSignIn/ExtraLoginModal";
import AdminExtraLoginModal from "./components/admin/AdminExtraLoginModal";
import { AdminUserRole } from "./pages/AdminUserRole";
import { PrimeReactProvider } from "primereact/api"
import UserPersonalInfo from "./components/employee/profile/PersonalInfo"
import WorkInfo from "./components/employee/profile/WorkInfo";
import Requests from "./components/employee/profile/Requests";
import Payment from "./components/employee/profile/Payment";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import useUserRole from './hooks/useUserRole'
import Header from "./components/layout/header/Header";


function App() {
  const userRole = useUserRole()
  console.log("USER ROLE: ", userRole)
  let tracker = {
    online:false,
    projectId:0,
    project:'default',
    weeklyTime: 0,
    dailyTime: 0
  }
  ReactSession.set("Tracker", tracker)
  useMemo(() => {
    window.addEventListener('beforeunload', async function(event) {
      let tracker = ReactSession.get("Tracker")
      let loggedUser = ReactSession.get("User")
      if(tracker.online){
        event.preventDefault();
        
        let key = {
            jobId: sessionStorage.getItem("jobID"),
            username: loggedUser.username
        }
        let keys = {
          ID: parseInt(sessionStorage.getItem('session')),
          Online: false
        }
        await put('DesktopApp/Online', keys)
        let response = await post("WebTracker/RemoveJob", key)
        if(response){
            tracker.online = false
            ReactSession.set("Tracker", tracker)
            alert("Your web tracker was set to offline, please turn it on again")
            window.location.reload()
        }

        return 'Are you sure you want to leave?';
      }
    });

  },[]);
  
  return (
    <PrimeReactProvider>
      {userRole !== undefined && (
        <Header />
      )}
      <div>
          <Routes>
            <Route path="/summary" element={<EmployeeSummaryDashboard />} />
            <Route path="/diary" element={<EmployeeDiary />} />
            <Route path="/profile" element={<EmployeeProfile />} />
            <Route path="/profile/info" element={<UserPersonalInfo />} />
            <Route path="/profile/requests" element={<Requests />}/>
            <Route path="/profile/payment" element={<Payment />}/>
            <Route path="/profile/work" element={<WorkInfo />} />
            {userRole === 'isManager' && (
              <>
                <Route path="/manager" element={<ManagerDashboard />} />
                <Route path="/manager/payrolldetails" element={<ManagerPayrollFullDetails />} />
                <Route path="/manager/audit" element={<Audit />} />
                <Route path="/manager/additional-payments" element={<AdditionalPayments />} />
                <Route path="/manager/summary/:employeeId" element={<UserSummaryDashboard />} />
                <Route path="/manager/personalInfo/:employeeId" element={<PersonalInfo />} />
                <Route path="/manager/diary/:employeeId" element={<UserDiary />} />
                <Route path="/manager/user-requests/:employeeId" element={<UsersRequest />} />
                <Route path="/manager/projects" element={<ManagerProjectsDashboard />} />
                <Route path="/manager/manual-time-approval" element={<ManagerManualTimeApproval />} />
                <Route path="/manager/compliance/:userId" element={<UserCompliance />}/>
              </>
            )}
            {userRole === 'isCustomer' && (
              <>
                <Route path="/team" element={<TeamDashboard />} /> 
                <Route path="/team/dashboard/:employeeId" element={<TeamMemberDashboard />} />
              </>
            )}
            {userRole === 'isAdmin' && (
              <>
              <Route path="/manager" element={<ManagerDashboard />} />
                <Route path="/manager/payrolldetails" element={<ManagerPayrollFullDetails />} />
                <Route path="/manager/audit" element={<Audit />} />
                <Route path="/manager/additional-payments" element={<AdditionalPayments />} />
                <Route path="/manager/summary/:employeeId" element={<UserSummaryDashboard />} />
                <Route path="/manager/personalInfo/:employeeId" element={<PersonalInfo />} />
                <Route path="/manager/diary/:employeeId" element={<UserDiary />} />
                <Route path="/manager/user-requests/:employeeId" element={<UsersRequest />} />
                <Route path="/manager/projects" element={<ManagerProjectsDashboard />} />
                <Route path="/manager/manual-time-approval" element={<ManagerManualTimeApproval />} />
                <Route path="/manager/compliance/:userId" element={<UserCompliance />}/>
                <Route path="/settings/sso-setup" element={<Settings />} />
                <Route path="/user-settings/trolley-report" element={<AdminTrolleyReport />} />
                <Route path="/user-settings/update-rate" element={<AdminUpdateRate />} />
                <Route path="/user-settings/user-role" element={<AdminUserRole />} />
                <Route path="/user-settings/additional-payments" element={<AdminAdditionalPayments />} />
                <Route path="/settings/announcements" element={<AdminAnnouncements />} />
                <Route path="/settings/web-tracker" element={<AdminWebTracker />} />
              </>
            )}
            {userRole === undefined && (
              <>
                <Route path="/manager/manual/:employeeId" element={<ExtraLoginModal />} />
                <Route path="/manager/overtime/:employeeId" element={<ExtraLoginModal />} />
                <Route path="/settings/additional-payments" element={<AdminExtraLoginModal />} />
              </>
            )}
            <Route path="/*" element={<Navigate to="/summary" />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
      </div>
    </PrimeReactProvider>
  );
  
}

export default App;
