import { MoonLoader } from "react-spinners";

function LoadingSpinner() {
    return (
        <div className="LoadingSpinner">
            <MoonLoader color="#004225"/>
        </div>
    )
}

export default LoadingSpinner;