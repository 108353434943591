import DataTableBase from "../ui/DataTableBase";
import {post} from "../api/apiCalls";
import React, { useState} from 'react';
import DatePicker from "react-datepicker";
import '../elements/dashboardElements/PayrollModal.css';
import moment from 'moment';
import LoadingSpinner from "../elements/UiElements/LoadingSpinner";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { AdminUserSettingsHeaderLink } from "../layout/header/AdminUserSettingsHeaderLink";
import Select from 'react-select';
import './TrolleySetup.css'


function TrolleySetup () {
    let [frequencyRange, setFrequencyRange] = useState(0)
    const frequencies = [
        {value: 1, label: 'Weekly'}, 
        {value: 2, label: 'Bi-Weekly'}, 
        {value: 3, label: 'Monthly'}
    ]
    let [selectedFrequency, setSelectedFrequency] = useState('');
    let [payrollData,setPayrollData] = useState([]);
    let [loading,setLoading] = useState(false);
    let [dates,setDates] = useState({
        fromDate : null,
        toDate : null,
    })

    const columns = [
        {
          id: 'Reference ID',
          name: 'Reference ID',
          selector: (row) => row['Reference ID'],
        },
        {
          id: 'Amount',
          name: 'Amount',
          selector: (row) => (row['Amount']),
        },
    ];

    const setFromDate = (date) => {
        dates.fromDate = date;
        setDates({...dates});

    }

    const setToDate = (date) => {
        dates.toDate = date;
        setDates({...dates});
        ValidatePayFrequencyDateRange()
    }

    const ValidatePayFrequencyDateRange = () => {
    const fromDate = new Date(dates.fromDate);
    const toDate = new Date(dates.toDate);
    const diffTime = toDate - fromDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    
        if (diffDays > frequencyRange) {
            alert(`You have selected "${selectedFrequency.label}" frequency but the selected date range is greater than ${frequencyRange} days. You will still be able to generate the report; this is just an alert`);
        }
    }

    const getPayrollDetails = async() =>   {
        if(dates.fromDate == null || dates.toDate == null)
            return;
        setLoading(true);
        let keys = { 
            from:moment(dates.fromDate).format("YYYY-MM-DD") , 
            to: moment(dates.toDate).format("YYYY-MM-DD 23:59:59"),
            byProject:true, 
            payFrequency: selectedFrequency.label 
        };
        try {
            let data = [];
            setPayrollData([]);
            let response = await post("Reports/TrolleyPayroll",keys);
            console.log("Response: ", response)
            for (const item of response) {
                let sum = item.TotalAmount
                let payrollFixes = item.payroll
                sum.toFixed()
                payrollFixes.toFixed()
                data.push({
                    "Email": ' ',
                    "Reference ID": item.Username,
                    "Recipient ID": ' ',
                    "Amount": Math.round(sum),
                    "Currency": ' ',
                    "Cover Fees": 'No',
                    "Taxable": 'No',
                    "Payment Reason": ' ',
                    "US Tax Activity": ' ',
                    "Memo": item.AdditionalPayments > 0? "Sirius payment for month: " + ((moment(dates.toDate).month() + 1) < 10? '0'+ (moment(dates.toDate).month() + 1) : (moment(dates.toDate).month() + 1)) + '/' + moment(dates.toDate).year() + " + Additional Payment: " + item.AdditionalPayments :"Sirius payment for month: " + ((moment(dates.toDate).month() + 1) < 10? '0'+ (moment(dates.toDate).month() + 1) : (moment(dates.toDate).month() + 1)) + '/' + moment(dates.toDate).year(),
                    "Tags": item.PayFrequency,
                    "External Id": ' '
                });
            }
            setPayrollData(data);
            setLoading(false);
        } catch(error) {
            console.log("ERROR: ", error)
        }
    }

    const handleSelectChange = (payFrequency) => {
        handlePayFrequencyDateRange(payFrequency)
        setSelectedFrequency(payFrequency)
    };

    function handlePayFrequencyDateRange(payFrequency){
        if(payFrequency.label === 'Monthly') setFrequencyRange(30)
            else if(payFrequency.label === 'Bi-Weekly') setFrequencyRange(15)
                else setFrequencyRange(7)
    }

    console.log("FROM DATE: ", dates.fromDate)
    return(
        <div className="settingsContainer">
            <AdminUserSettingsHeaderLink/>
            {  
                <Box sx={{ flexGrow: 1 }} className="settingsBody">
                    <Grid container spacing={2}>
                        <Grid xs={10}>
                            <h3 className="settingsLabel">Trolley Report</h3>

                        </Grid>
                        <Grid xs={10}>
                            <Select
                                className="trolley-dropdown"
                                classNamePrefix="select"
                                placeholder="Select a frequency"
                                name="color"
                                value={selectedFrequency}
                                defaultValue={selectedFrequency}
                                onChange={handleSelectChange}
                                options={frequencies}
                            />
                            <div className="dateSelector">
                                <small>From</small>
                                <DatePicker
                                selected={dates.fromDate}
                                onChange={(date) => setFromDate(date)}
                                selectsStart
                                maxDate={new Date()}
                                className="no-border trolley-report-date-select"
                                calendarStartDay={1}
                                disabled={selectedFrequency === ''}
                                />
                            </div>
                            <div className="dateSelector">
                                <small>To</small>
                                <DatePicker
                                    selected={dates.toDate}
                                    onChange={(date) => setToDate(date)}
                                    selectsEnd
                                    minDate={dates.fromDate}
                                    maxDate={new Date()}
                                    className="no-border trolley-report-date-select"
                                    calendarStartDay={1}
                                    disabled={dates.fromDate == null}
                                />
                            </div>
                            <button 
                            className="generate-trolley-report-btn"
                            onClick={getPayrollDetails}
                            disabled={selectedFrequency === '' || dates.fromDate === null || dates.toDate === null}
                            >Generate</button>
                        </Grid>
                        <Grid xs={10}>
                        <div className='project-details-card'>
                            <div id='section-project-members'>
                                {!loading && (
                                <DataTableBase data={payrollData} columns={columns}
                                />)
                                }
                                {loading && (
                                <LoadingSpinner/>
                                )}
                                
                            </div>
                        </div>
                        </Grid>
                    </Grid>
                </Box>
            }
        </div>
    );
}

export default TrolleySetup;