import "./WorkInfo.css";
import { Link } from "@mui/material";
import { RiLockPasswordFill } from "react-icons/ri"
import { AiOutlineMail } from "react-icons/ai"
import { BsChatDots, BsCardChecklist } from "react-icons/bs"
import { MdOutlineContactPhone } from "react-icons/md"
import { SiJirasoftware, SiStatuspal } from "react-icons/si"
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { BsLink45Deg } from "react-icons/bs"
import { ReactSession } from 'react-client-session'
import ProfileSubHeaderLinks from "../../layout/header/ProfileSubHeaderLinks";
import ManagerSideBar from "../../layout/sidebar/ManagerSidebar";


function WorkInfo(props) {
  const history = useNavigate();
  const [isFirstLogin, setIsFirstLogin] = useState('true')
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: "Here you can find links to the most commonly used systems within our organization. From a chat tool to our training platform. We have it all!",
        title: <h2><BsLink45Deg /> Links page</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body'
      },
      {
        content: 'As part of your onboarding, please go to the LMS Systems link and complete the InfoSec training.',
        placement: "top",
        target: "#step-links",
        spotlightClicks: true,
        title: <h2>Links</h2>
      },
    ]
  })
  
  const handleTourEvent = (event) => {
    console.log(event)
    if(event.index == 1 && event.status == 'finished' || event.action == 'skip'){
      history("/profile/payment")
    }
  }

  useMemo(() => {
    const user = ReactSession.get("User");
    setIsFirstLogin(user.isFirstLogin)
  },[]);

  return (
    <>
      <ManagerSideBar />
      <div className="links-box">
        <ProfileSubHeaderLinks />
        <div className="employee-main" >
          {isFirstLogin && (
            <Joyride
                continuous
                showSkipButton
                run={run}
                steps={steps}
                showProgress
                disableScrolling
                callback={handleTourEvent}
                styles={{
                  options: {
                    zIndex: 9999,
                  },
                  spotlight:{
                    minHeight:'100%'
                  }
                }}
            />
          )}
          <div className="employee-main-work-info">
          
            <div className="work-info-header" >Links</div>
              <ul id="step-links">
              <li><Link href="https://moodle-lms.sirius-support.com/login" target="_blank"><BsCardChecklist></BsCardChecklist> LMS Systems</Link></li>
              <li><Link href="https://quinndesk.sirius-support.com/" target="_blank"><MdOutlineContactPhone></MdOutlineContactPhone> QuinnDesk</Link></li>
              <li><Link href="https://www.rock.so/" target="_blank"><BsChatDots></BsChatDots> Internal chat tool</Link></li>
              <li><Link href="https://vault.sirius-support.com/" target="_blank"><RiLockPasswordFill></RiLockPasswordFill> Password management system</Link></li>
              <li><Link href="https://webmail.sirius-support.io/" target="_blank"><AiOutlineMail></AiOutlineMail> Sirius email</Link></li>
              <li><Link href="https://sirius-support.atlassian.net/servicedesk/customer/portals" target="_blank"><SiJirasoftware></SiJirasoftware> Jira Portal for System bugs</Link></li>
              <li><Link href="https://kuma.sirius-support.com/status/siriussystems" target="_blank"><SiStatuspal></SiStatuspal> Systems Status Page</Link></li>
              </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default WorkInfo;
