
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import TeamMember from "../components/team/teamdashboard/TeamMember";
import React, { useState,useMemo} from 'react';
import { ReactSession } from 'react-client-session';
import { get } from "../components/api/apiCalls";
import { useParams } from "react-router-dom";

function TeamMemberDashboard() {

  let [users, setUsers] = useState(null);
  let [showUserData,setShowUserData]= useState(false);
  let user = ReactSession.get("User");
  const params = useParams();

//temp solution for preventing customer from accessing unauthorized users  
  useMemo(async () => {
    let usersList = []
    if(users==null){
      usersList = await get("Projects/GetAgentsForProject",{ projectID : user.project.id }).then();
      setUsers(usersList);
      usersList.forEach(user => {
        if(user.id == params.employeeId){
          setShowUserData(true);
        }
       });
    }
  },[]);


  return (
    <>
      <ManagerSidebar searchTo="team" />

      {showUserData && <TeamMember />}  
    </>
  );
}

export default TeamMemberDashboard;
