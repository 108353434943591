import './DashboardProjects.css';
import DataTableBase from '../../ui/DataTableBase.js';
import Backdrop from '../../ui/Backdrop.js';
import ProjectsModal from '../../elements/dashboardElements/ProjectsModal.js';
import AddProjectsModal from '../../elements/dashboardElements/AddProjectsModal.js';
import PayrollModal from '../../elements/dashboardElements/PayrollModal.js'
import {post,get} from "../../api/apiCalls";
import React, { useState,useMemo} from 'react';
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import Switch from "react-switch";

function DashboardManagerProjects(props) {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [payrollModal,setPayrollModal] = useState(false);
  let [projects,setProjects] = useState([]);
  let [loading,setLoading] = useState(true);
  let [disabled,setDisabled] = useState(false); 
  
  const toggleOnlyActive = () => {
      disabled = !disabled;
      setDisabled(disabled);
      console.log("disabled: ", disabled)
      getProjects();
  }

  const columns = [
    {
      id: 'project',
      name: 'Project',
      selector: (row) => row.project,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'contact',
      name: 'Contact Name',
      selector: (row) => row.contact,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'manager',
      name: 'Manager',
      selector: (row) => row.manager,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'shared',
      name: 'Shared Services',
      selector: (row) => row.shared,
      sortable: true,
      reorder: true,
      wrap: true,
      cell: (row) => {
        return (
          <div className='project-services' id={row.id}>
            {row.shared ? 'Yes' : 'No'}
          </div>
        );
      },
    },
    {
      id: 'disabled',
      name: 'Disabled',
      selector: (row) => row.disabled,
      sortable: true,
      reorder: true,
      wrap: true,
      cell: (row) => {
        return (
          <div className='project-services' id={row.id}>
            {row.disabled ? 'Yes' : 'No'}
          </div>
        );
      },
    },
    {
      id: 'details',
      name: 'Details',
      selector: (row) => row.details,
      cell: (row) => {
        return (
          <button
            className='project-details-buttons'
            onClick={() => showDetails(row)}
          >
            Show Details
          </button>
        );
      },
    },
    {
      id: 'report',
      name: 'Report',
      selector: (row) => row.details,
      cell: (row) => {
        return (
          <button
            className='project-details-buttons'
            onClick={() => showPayrollSummary(row)}
          >
            Invoice
          </button>
        );
      },
    },
  ];

  let showPayrollSummary  = async(modalData) => {
    //let 
    setModalData({...modalData});
    setPayrollModal(true);
    
  }

  let showDetails = async(modalData) => {
    let agents = [];
    
    let response = await get("Projects/GetAgentsForProject?projectID="+modalData['id']);
    for(let i = 0 ; i < response.length ; i++) {
      agents.push({
        id : response[i]['id'],
        agentname : response[i]['firstName'] + " " + response[i]['lastName'],
        username : response[i]['username']
      })
    }
    modalData.members = agents;
    setModalData({...modalData});
    setOpenModal(true);
    
  }

  function closeDetails(row) {
    setOpenModal(false);
    getProjects();
    setModalData([]);
  }

  function openAddProjectsModal() {
    setOpenAddModal(true);
  }

  function closeAddModal() {
    setOpenAddModal(false);
  }
  
  const getProjects = async() => {
    setLoading(true);
    let projectResult
    let projectsList = []
    if(disabled){
      projectResult = await get(`Projects`);
      projectsList = [];
    }else{
      projectResult = await get(`Projects?disabled=${disabled}`);
      projectsList = [];
    }
    for(let i = 0 ; i < projectResult.length ; i++){
      let projectEntity = projectResult[i];
      let project = {
        id : projectEntity['Id'],
        project: projectEntity['Name'],
        contact: projectEntity['ContactName'],
        manager: projectEntity['Manager'] !=null ? projectEntity['Manager']: '',
        managerId: projectEntity['ManagerId'] !=null ? projectEntity['ManagerId'] : 0,
        disabled: projectEntity['Disabled'],
        shared: projectEntity['SharedService'],
        hours: projectEntity['HourCap'],
        members : [{
          id: 1055,
          agentname: 'Jane Doe',
        }]
      }
      projectsList.push(project);
    }
    setProjects(projectsList);
    setLoading(false);
  }

  function closePayrollModal () {
    setPayrollModal(false);
  }

  const addProject = async(projectObject) => {
    let response = await post("Projects",projectObject);
    if(response == undefined){
      alert("Project name and Contact Name can't be empty \n Hour cap must be greater than 0")
    }
    console.log(response)
    console.log(response);
    closeAddModal();
    getProjects();
  }
  
  useMemo(() => {
    //if(summary===0)
      getProjects();
    // componentWillMount events
  },[]);

  return (
    <div className='dashboardProjectsMain'>
      <div className='dashboardProjectsMain-header'>Projects</div>
      <div>
        <button
          className='project-add-button'
          id='add-projects-button'
          onClick={openAddProjectsModal}
        >
          Add Project
        </button>
      </div>
      <div className="onlyActive projects-onlyActive">
        <label htmlFor="onlyActive" >Only Active</label>
        {/* <input checked={disabled} type="checkbox" name="onlyActive" onChange={() => toggleOnlyActive()}/> */}
        <Switch onChange={() => toggleOnlyActive()} checked={!disabled} />
      </div>
      {(() => {
              if (!loading){
                  return (
                    <div>

                    <DataTableBase
                      columns={columns}
                      data={projects}
                      tableName='ProjectsReport'
                      fileName={"Projects"}
                    />
                    {openModal && (
                      <ProjectsModal modalData={modalData} onCancel={closeDetails} updateDetails={showDetails} saveChanges={closeDetails}/>
                    )}

                    {payrollModal && (
                      <PayrollModal modalData={modalData} onCancel={closePayrollModal}/>
                    )}
                    </div>
                  )
              } else {
                return <LoadingSpinner/>
              }
      })()}
      
      {payrollModal && <Backdrop onCancel={closePayrollModal} /> }

      {openModal && <Backdrop onCancel={closeDetails} />}
      {openAddModal && <AddProjectsModal onCancel={closeAddModal} addProject={addProject} />}
      {openAddModal && <Backdrop onCancel={closeAddModal} />}
    </div>
  );
}

export default DashboardManagerProjects;
