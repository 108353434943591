import "./Compliance.css"
import { useParams } from "react-router-dom";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { useEffect, useState } from "react";
import { get } from "../../api/apiCalls";
import { TbListDetails } from "react-icons/tb";
import ComplianceModal from "../dashboard/ComplianceModal";
import DataTableBase from "../../ui/DataTableBase";

function UserCompliances(){
    const params = useParams()
    const [compliance, setCompliance] = useState('')
    const [compliances, setCompliances] = useState([])
    const [openModal, setOpenModal] = useState(false)
    let [loading, setLoading] = useState(true)

    const columns = [
        {
            name: "User",
            selector: (row) => row.user,
            sortable: true,
            reorder: true,
        },
        {
            name: "Compliance Type",
            selector: (row) => row.complianceType,
            sortable: true,
            reorder: true,
        },
        {
            name: "Project",
            selector: (row) => row.project,
            sortable: true,
            reorder: true,
        },
        {
            name: "Manager",
            selector: (row) => row.manager,
            sortable: true,
            reorder: true,
        },
        {
            name: "Create Date",
            selector: (row) => row.createDate,
            sortable: true,
            reorder: true,
        },
        {
            name: "Status",
            selector: (row) => row.isOpen === true? 'Open': 'Closed',
            sortable: true,
            reorder: true,
        },
        {
            name: "Updated At",
            selector: (row) => row.updatedAt,
            sortable: true,
            reorder: true,
        },
        {
            name: "Updated By",
            selector: (row) => row.updatedBy,
            sortable: true,
            reorder: true,
        },
        {
            name: "Detail",
            selector: (row) => {
                return(
                    <button className="compliance-detail-btn" onClick={() => openComplianceDetails(row)}>
                        <TbListDetails />
                    </button>
                )
            },
            sortable: true,
            reorder: true,
        },
        
    ];

    async function getCompliances(){
        let response = await get(`Compliance?userID=${params.userId}`)
        setCompliances(response)
    }

    async function closeModal(){
        setOpenModal(false)
        await getCompliances()
    }

    async function closeModalByClick(){
        setOpenModal(false)
        await getCompliances()
    }

    function openComplianceDetails(e){ 
        setCompliance(e)
        setOpenModal(true)
    }

    useEffect(() => {
        const fetchData = async() => {
            try {
              await getCompliances()
              setLoading(false)
            } catch (error) {
              console.error("ERROR: ", error)
            }
          }
          
          fetchData()
    }, [])
    return(
        <div className="compliances">
            <div className="compliance-main-content">
                <ManagerSubHeaderLinks id={params.userId}/>
                
                <div className="complianceDiary">
                   <h1>Compliance Items</h1>
                    {!loading && (
                        <DataTableBase columns={columns} data={compliances}  fileName={"user_compliance"}/>
                    )}
                </div>
            </div>
        {openModal && <ComplianceModal compliance={compliance} onCloseModal={closeModal} onCloseModalByClick={closeModalByClick}/>}
        </div>
    )
}

export default UserCompliances;