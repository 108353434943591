
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserPersonalInfo from "../components/manager/employeeprofile/UserPersonalInfo";


function PersonalInfo() {
  return (
    <div>
      <ManagerSidebar />
      <UserPersonalInfo/>
    </div>
  );
}

export default PersonalInfo;
