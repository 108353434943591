import "./SummaryCard.css";
import { FaInfoCircle } from "react-icons/fa";

function SummaryCard(props) {
  return (
    <div className="summaryCard">
      <div className="summaryCardTitle">{props.title} <FaInfoCircle title={props.info}/></div>
      <div className="summaryCardValue">{props.cardtime}</div>
    </div>
  );
}

export default SummaryCard;
