import { GrClose } from 'react-icons/gr';
import DataTableBase from '../../ui/DataTableBase.js';
import {post} from "../../api/apiCalls";
import React, { useEffect, useState} from 'react';
import { ReactSession } from 'react-client-session';
import DatePicker from "react-datepicker";
import './PayrollModal.css';
import {expFile} from "./ReportDoc.js"
import moment from 'moment';
import LoadingSpinner from "../UiElements/LoadingSpinner";

function PayrollModal(props) {
  let [myData,setProjectData] = useState(props.modalData);
  let [payrollData,setPayrollData] = useState([]);
  let docElement = "";
  let byProject = true;
  let [loading,setLoading] = useState(false);
  let [dates,setDates] = useState({
    fromDate : null,
    toDate : null,
  })

  const setFromDate = (date) => {
    dates.fromDate = date;
    setDates({...dates});
    getPayrollDetails(dates.fromDate,dates.toDate);

  }

  const setToDate = (date) => {
    dates.toDate = date;
    setDates({...dates});
    getPayrollDetails(dates.fromDate,dates.toDate);
  }

  const getPayrollDetails = async(from,to) =>   {
    if(from == null || to == null)
      return;
    setLoading(true);
    let keys = { "from":moment(from).format("YYYY-MM-DD") , "to": moment(to).format("YYYY-MM-DD 23:59:59"),"byProject":true };
    try {
      // make axios post request
      let data = [];
      setPayrollData([]);
      let response = await post("Reports/Payroll",keys);
      let id = 1;
      for(let i = 0 ; i < response.length ; i++){
        if(response[i].project == myData.project)
        data.push({
          id: id++,
          email: response[i].Username,
          name: response[i].Name,
          totalTracked: response[i].totalTime,
          productive: response[i].ActiveTime,
          manual: response[i].requested,
          idle: response[i].IdleTime,
          payroll: response[i].payroll,
          project: byProject ? response[i].project : "",
          eId : response[i].ID
        });
      }
      setPayrollData(data);
      setLoading(false);
    } catch(error) {
      

      
    }
    

  }

  const columns = [
    {
      id: 'name',
      name: 'Name',
      selector: (row) => row.name,
    },
    {
      id: 'payroll',
      name: 'Payroll Time',
      selector: (row) => row.removeagent,
      cell: (row) => row.payroll
    },
  ];
  
  let downloadHtml = async () => {
    let user = ReactSession.get("User");
    let key = { details : `projectName:${myData.project} ,from : ${moment(dates.fromDate).format("YYYY-MM-DD")} , to: ${moment(dates.toDate).format("YYYY-MM-DD 23:59:59")} `, "userID" : user.id, fileDownloaded : "formal docx invoice"};
    await post("Logger/CriticalLog",key);
    let keys = { "from":moment(dates.fromDate).format("YYYY-MM-DD") , "to": moment(dates.toDate).format("YYYY-MM-DD 23:59:59"),"projectName" : myData.project };
    let response = await post("Reports/ProjectPayrollWeekly",keys);
    myData.payroll = response;
    myData.fromDate = dates.fromDate;
    myData.toDate = dates.toDate;
    myData.month = moment(dates.fromDate).month()+1;
    myData.year = moment(dates.fromDate).year();

    // call the funcion to generate the plot
    expFile(myData);
  }

  let downloadInvoice = async() => {
    let user = ReactSession.get("User");
    let keys = { details : `projectName:${myData.project} ,from : ${moment(dates.fromDate).format("YYYY-MM-DD")} , to: ${moment(dates.toDate).format("YYYY-MM-DD 23:59:59")} `, "userID" : user.id, fileDownloaded : "invoice"};

    await post("DesktopApp/CriticalLog",keys);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
      props.onCancel(); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.onCancel])
  
  return (
    <div className='form-modal project-modal' id='project-form-modal'>
      <div className='close-modal' onClick={props.onCancel}>
        <GrClose />
      </div>
      <div id='project-form'>
        <div className='project-details-card'>
          <div className='project-headers'><b>{myData.project}</b> - Invoice</div>
        <div className="dateSelector">
          <small>From</small>
          <DatePicker
            selected={dates.fromDate}
            onChange={(date) => setFromDate(date)}
            selectsStart
            maxDate={new Date()}
            className="no-border dateSelect"
            calendarStartDay={1}
            //customInput={<CalendarStartDateSelector />}
          />
        </div>
        <div className="dateSelector">
          <small>To</small>
          <DatePicker
            selected={dates.toDate}
            onChange={(date) => setToDate(date)}
            selectsEnd
            maxDate={new Date()}
            className="no-border dateSelect"
            calendarStartDay={1}
            disabled={dates.fromDate == null}
            //customInput={<CalendarEndDateSelector />}
          />
        </div>
        </div>
        <div className='project-details-card'>
          <div id='section-project-members'>
            {!loading && (
              <DataTableBase data={payrollData} columns={columns} 
              fileName={`invoice_${myData.project}_${dates.fromDate}_${dates.toDate}`} 
              downloadCall={downloadInvoice}
              />)
            }
            {loading && (
              <LoadingSpinner/>
            )}
            
          </div>
        </div>
        <button  className='project-info-button' onClick={downloadHtml}>
          Print Invoice
        </button>
      </div>
      <div id="docElement" >
        {docElement}
      </div>
    </div>
  );
}

export default PayrollModal;
