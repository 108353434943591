import { Box, Modal, Typography } from '@mui/material';
import React, { useState, useEffect  } from 'react';
import { ReactSession } from "react-client-session"
import "./ExtraLoginModal.css"
import { post } from '../../api/apiCalls';

function ExtraLoginModal() {
    const [open, setOpen] = useState(true);
    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')
    const [urlAtual, setUrlAtual] = useState('');
    /**
     * @description login function
     * @param {string} username user email
     * @param {string} password user password
     * @returns {object} return the user datas
     */
    const doThelogin = async(username, password) => {
        let key = {
            email: username,
            password,
            sso: false
        }

        const user = await post("Users/Login", key)
        if(user){
            ReactSession.set("User", user)
            console.log(user)
            if(user.role == 1 || user.role == 3) {
                
                window.location.href = urlAtual
            } else if(!user.role == 1 || !user.role == 3){
                window.location.href = "/";
            }
        }
        else{
            alert('Wrong username or password');
        }
        
    }

    function cancel(e){
        e.preventDefault()
        window.location.href = "/"
    }

    function login(e){
        e.preventDefault()
        doThelogin(username, password)
    }

    useEffect(() => {
        setUrlAtual(window.location.href)
    }, [])
    return (
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='extra-modal-box'
        >
            <Box className='extra-login-modal-box'>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Please, login before access this page
                </Typography>
                <Box>
                    <form>
                        <div>
                            <label htmlFor='email'>Email</label>
                            <input id='email' type='text' placeholder='Email' onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor='password'>Password</label>
                            <input id='password' type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <button 
                        type='submit' 
                        className='extra-login-btn' 
                        onClick={login}
                        disabled={!password || !username}
                        >Login
                        </button>
                        <button 
                        type='cancel' 
                        className='extra-cancel-btn' 
                        onClick={cancel}
                        >Cancel
                        </button>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
}

export default ExtraLoginModal;
