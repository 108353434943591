
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import TeamDashboard from "../components/layout/main/TeamDashboard";

function ManagerDashboard() {
  return (
    <div>
      <ManagerSidebar />
      <TeamDashboard />
    </div>
  );
}

export default ManagerDashboard;
