import EmployeeDailyDiary from "../components/employee/dashboard/EmployeeDailyDiary";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import Joyride from "react-joyride"
import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { ReactSession } from "react-client-session"
import { put } from "../components/api/apiCalls";
import { BiDetail } from "react-icons/bi";
function EmployeeDiary() {
  const history = useNavigate();
  const [isFirstLogin, setIsFirstLogin] = useState('true')
  const [userId, setUserId] = useState()
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: 'Here you can find your timecards. You can manage those timecards in this section as well.',
        title: <h2><BiDetail /> Diary Page</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body'
      },
      {
        content: 'By selecting the checkboxes and clicking on delete or mark as active, you can manage your own time cards. Note that overtime is automatically declined unless approved.',
        placement: "top",
        target: ".step-0",
        title: <h2>Diary</h2>
      },
      {
        content: 
        <p>
          You have successfully completed your first onboarding journey. 
          <br/>
          <br/>
          Welcome to Sirius Support!
        </p>,
        placement: "center",
        target: "body",
        title: <h2>Wisdom</h2>
      },
    ]
  })
  
  const handleTourEvent = async (event) => {
    console.log(event)
    if(event.action == 'skip' || event.action == 'close' || event.index == 2 && event.status == 'finished'){
      let keys = {
        userId : userId,
      };
      await put("Users/UpdateIsFirstTimeLogin", keys)
      let user = ReactSession.get("User")
      user.isFirstLogin = false
      ReactSession.set("User", user)
      
      history("/summary")
    }
  }

  useMemo(() => {
    const user = ReactSession.get("User");
    setIsFirstLogin(user.isFirstLogin)
    setUserId(user.id)
  },[]);
  return (
    <div>
      {isFirstLogin && (
        <Joyride
          continuous
          showSkipButton
          run={run}
          steps={steps}
          showProgress
          disableScrolling
          callback={handleTourEvent}
          styles={{
            options: {
              zIndex: 9999,
            }
          }}
        />
      )}
      <ManagerSidebar />
      <EmployeeDailyDiary />
    </div>
  );
}

export default EmployeeDiary;
