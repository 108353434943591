import {ReactSession} from 'react-client-session'

function useUserForbidLine(){
    let user = ReactSession.get("User");
    console.log("USER: ", user)
    if(user !== undefined){
        if(user.role === 1) return 'isAdmin'
        if(user.role === 2) return 'isUser'
        if(user.role === 3) return 'isManager'
        if(user.role === 4) return 'isCustomer'
    }
};

export default useUserForbidLine;