import TrolleySetup from "../components/admin/TrolleySetup"

import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";


function AdminTrolleyReport() {
  return (
    <div>
      <ManagerSidebar />
      <TrolleySetup />
    </div>
  );
}

export default AdminTrolleyReport;
