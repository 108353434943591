
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserSummary from "../components/manager/dashboard/UserSummary";

function UserSummaryDashboard() {
  return (
    <div>
      <ManagerSidebar />
      <UserSummary assignedProjects="2" />
    </div>
  );
}

export default UserSummaryDashboard;
