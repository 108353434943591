import UserRole from "../components/admin/UserRole";

import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";

export function AdminUserRole(){

    return(
        <div>
            <ManagerSidebar />
            <UserRole />
        </div>
    )
}