import AdminSettings from "../components/admin/AdminSettings";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";

function Settings () {
    return (
        <div>
            <ManagerSidebar />
            <AdminSettings />
        </div>);
}

export default Settings;