
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import AdminWebTrackerPage from "../components/admin/AdminWebTrackerPage";

function AdminWebTracker() {
  return (
    <div>
      <ManagerSidebar />
      <AdminWebTrackerPage />
    </div>
  );
}

export default AdminWebTracker;
