import "../../manager/dashboard/UserSummary.css";
import "./TeamMember.css";
import DateRangeSelector from "../../ui/DateRangeSelector";
import SummaryCard from "../../ui/SummaryCard";
import SearchSection from "../../ui/SearchSection";
import EmployeeShortProfile from "../../elements/summaryElements/EmployeeShortProfile";
import DateSelector from "../../ui/DateSelector";
import DiaryTimeLine from "../../elements/diaryElements/DiaryTimeline";
import { useParams } from "react-router-dom";
import { fetchImageFromAPI, post } from "../../api/apiCalls";
import React, { useMemo, useState } from 'react';
import { ReactSession } from 'react-client-session';
import moment from 'moment';
import { baseURL } from "../../api/env";
import DefaultScreenshot from '../../../images/noScreenshot.jpg'
import DefaultCamShot from '../../../images/noCamshot.jpg'
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";


function TeamMember(props) {
  let userSession = ReactSession.get("User");

  const params = useParams();
  const [TotalTime, setTotalTime] = useState(0);
  let [user,setUser] = useState(0);
  let [connect,setConnect] = useState(0);
  let [from, setFrom] = useState(0);
  let [to, setTo] = useState(0);
  let [loading,setLoading] = useState(false);
  let [date, setdate] = useState(0);

  let [rsummary, setSummary] = useState([]);
  let [attendenceDetail,setAttendanceDetail] = useState();
  let selectedDiaryDate = moment().format("YYYY-MM-DD");
  const setDiaryDate = (date) => {
    setdate(date);
    selectedDiaryDate = date;
    getDiarySummaryDetails();
  };

  if(user!=0 ){
    if(user.image == null)
      user.image = "default";

  }

  const getUser = async () => {
   let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    setUser(u);
  }
   
  if(connect==0){
    setConnect(1);
    getUser();
  }
  const getSummaryDetails = async () => {
    let keys = {
      from : moment(from).format("YYYY-MM-DD"),
      to : moment(to).format("YYYY-MM-DD 23:59:59"),
      projectID : userSession.project.id,
      userID : params.employeeId
    };
    summary = await post("Customer/UserPayrollProject",keys);
    setTotalTime(summary.payroll);
      
  };
  
  let summary;
  const setFromDate = (date) => {
    setFrom(date);
    getSummaryDetails();
  };

  const setToDate = (date) => {
    setTo(date);
    getSummaryDetails();
  };

  const getDiarySummaryDetails = async () => {
    setLoading(true)
    try {
      const keys = {
        date: moment(selectedDiaryDate).format("YYYY-MM-DD"),
        id: params.employeeId,
        projectID: userSession.project.id
      };
  
      const rsummary = await post("TrackingData/UserTrackingData", keys);
      await fetchImages(rsummary);
  
      if (!rsummary) {
        alert('No data');
        return;
      }
  
      const line = [];
      const allDiary = [];
      const attendance = new Array(Math.ceil(rsummary.length / 6)).fill(0);
  
      let activeDay = false;
  
      rsummary.forEach((entry, i) => {
        if (entry !== null) {
          activeDay = true;
          const hour = Math.floor(i / 6);
          attendance[hour]++;
        }
  
        if ((i + 1) % 6 === 0 && line.length > 0) {
          allDiary.push(<div className="diaryHours" id="diaryHours">{line}</div>);
          line.length = 0;
        }
      });
      let timeout = rsummary.length * 1000 > 29999 ? 29999 : rsummary.length * 1000
      setTimeout(() => {
        if (activeDay) {
          setFromDate(keys.date);
          setAttendanceDetail(attendance);
          setSummary(rsummary);
        }
        setLoading(false)
      }, timeout);
    } catch (error) {
      console.error("Error fetching diary summary details:", error);
    }
  };

  const fetchImages = async(items) => {
    items.forEach(async (data) => {
      let screenshot = data.screenshotPath === undefined ? DefaultScreenshot:  await fetchImageFromAPI(`${baseURL}TrackingData/GetScreenshots?ImagePath=screenshots/` + data.screenshotPath + ".jpg");
      let webcam= data.webcamPath === undefined ? DefaultCamShot :await fetchImageFromAPI(`${baseURL}TrackingData/GetWebcam?ImagePath=webcam/`+ data.webcamPath + ".jpg");
      data.screenshotPath = screenshot
      data.webcamPath = webcam
    })
  }

  useMemo(() => {
    
    let date = moment();
    setFrom(date);
    setTo(date);
    from = date;
    to = date;
    getSummaryDetails();
    console.log("Getting into getDiarySummaryDetails")
    getDiarySummaryDetails();
  },[]);
 
  return (
    <div className="team-member" id="team-member-details">
      <SearchSection destinationLink="/team" searchText="Search Team Member" />
      <div className="summaryMain teamMember">
      <EmployeeShortProfile
            employeeName={user.firstName + ' ' + user.lastname}
            employeeDesignation={user.job_Description}
            employeePronoun={user.pronoun}
            employeeID={user.id}
            employeeUsername={user.username}
            employeeLocation={user.city}
            employeePicture={user.image===null?"default":user.image}
        />
        <div className="summaryDiary-user teamMember">
          <div className="summaryContent teamMember">
            <div className="summaryHeader teamMember">{} Summary</div>
            <div className="selectors-row teamMember">
              <div className="selectors teamMember">
              <DateRangeSelector
                    onFromChangeDate = {setFromDate}
                    onToChangeDate = {setToDate}  />
                  
              </div>
              <div className="summaryCards teamMember">
                <SummaryCard title="Productive Time" cardtime={TotalTime} />
              </div>
            </div>
          </div>
          <div className="diaryContent teamMember">
            <div className="diaryHeader teamMember">
              <div className="summaryHeader teamMember">{} Daily Diary</div>
              <DateSelector placeholderText={moment().format("YYYY-MM-DD")} updateTimers={setDiaryDate} />
              {loading && (
                <LoadingSpinner />
              )}
            </div>
            
                <div className="dailyDiaryContainer">
                  <DiaryTimeLine
                  data = {rsummary}
                  />
                </div>
           
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
