import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import AdminAdditionalPaymentsPage from "../components/admin/AdminAdditionalPaymentsPage";

function AdminAdditionalPayments() {
  return (
    <div>
      <ManagerSidebar />
      <AdminAdditionalPaymentsPage />
    </div>
  );
}

export default AdminAdditionalPayments;
