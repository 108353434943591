import { MdLocationOn } from "react-icons/md";
import "./EmployeeCard.css";

function EmployeeCard(props) {
  return (
    <div className="employee-card">
        <div className="card-header">
          <img  className="card-image" src={props.employeePicture} alt={props.employeeName} />
          <p>{props.employeeID}</p>
        </div>
        <p>{props.employeeName}<br/>{props.employeeDesignation}<br/><MdLocationOn />{props.location}</p>
    </div>
  );
}

export default EmployeeCard;
