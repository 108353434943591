
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar.js";
import DashboardManualTimeApproval from "../components/manager/teamDashboards/DashboardManualTimeApproval.js";

function ManagerManualTimeApproval() {
  return (
    <>
      <ManagerSidebar />
      <DashboardManualTimeApproval />
    </>
  );
}

export default ManagerManualTimeApproval;
