import { useEffect, useState } from "react";
import { put } from '../../api/apiCalls'
import { ReactSession } from 'react-client-session';
import './ComplianceModal.css'
function ComplianceModal({compliance, onCloseModal, onCloseModalByClick}){
    let [disabled, setDisabled] = useState(true)
    const user = ReactSession.get('User')

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
            onCloseModal(); 
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onCloseModal]);

    function handleCloseModal(){
        onCloseModalByClick()
        
    }

    function handleClosureReason(e){
        compliance.closureReason = e.target.value
        if(e.target.value !== '')
            setDisabled(false)
        else
            setDisabled(true)
    }

    function handleAgentDiscussion(e){
        compliance.agentDiscussionSummary = e.target.value
        if(e.target.value !== '')
            setDisabled(false)
        else
            setDisabled(true)
    }

    function handleReviewedWithAgentStatus(e){
        if(e.target.value !== compliance.reviewedWithAgent)
            setDisabled(false)
        compliance.reviewedWithAgent = e.target.value
    }

    function handleIsOpenStatus(e){
        if(e.target.value !== compliance.isOpen)
            setDisabled(false)
        compliance.isOpen = e.target.value
    }
    
    async function handleComplianceUpdate(event){
        event.preventDefault();
        let keys = {
            ID: compliance.id,
            ReviewedWithAgent: compliance.reviewedWithAgent,
            AgentDiscussionSummary: compliance.agentDiscussionSummary,
            ClosureReason: compliance.closureReason,
            IsOpen: compliance.isOpen,
            Updatedby: user.firstName + ' ' + user.lastname
        }
        let response = await put('Compliance', keys)
        alert(response)
        onCloseModalByClick()
    }

    return(
        <div className="compliance-modal">
            <div className="compliance-modal-box">
                <h4>Compliance Item</h4>
                <form action="submit" className="compliance-modal-form" onSubmit={handleComplianceUpdate}>
                    <div className="compliance-modal-item-header">
                        <div>
                            <label htmlFor="compliance-id">Compliance #</label>
                            <input id="compliance-id" type="text" readOnly defaultValue={compliance.id} />
                        </div>
                        <div>
                            <label htmlFor="compliance-user">User</label>
                            <input id="compliance-user" type="text" readOnly defaultValue={compliance.user} />
                        </div>
                        <div>
                            <label htmlFor="compliance-manager">Manager</label>
                            <input id="compliance-manager" type="text" readOnly defaultValue={compliance.manager} />
                        </div>
                        <div>
                            <label htmlFor="compliance-project">Project</label>
                            <input id="compliance-project" type="text" readOnly defaultValue={compliance.project} />
                        </div>
                    </div>

                    <div className="compliance-modal-item-body">
                        <div>
                            <label htmlFor="compliance-type">Compliance Type</label>
                            <input id="compliance-type" type="text" readOnly defaultValue={compliance.complianceType} />
                        </div>
                        <div>
                            <label htmlFor="compliance-detail">Compliance Detail</label>
                            <textarea id="compliance-detail" value={compliance.detail} rows={3} readOnly/>
                        </div>
                        <div className="compliance-item-body-info">
                            <div>
                                <label htmlFor="compliance-reviewed">Reviewed with agent</label>
                                <select id="compliance-reviewed" onChange={handleReviewedWithAgentStatus} defaultValue={compliance.reviewedWithAgent}>
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="compliance-isOpen">Open</label>
                                <select id="compliance-isOpen" onChange={handleIsOpenStatus} defaultValue={compliance.isOpen}>
                                    <option value={false}>Close</option>
                                    <option value={true}>Open</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="compliance-createDate">Created at</label>
                                <input id="compliance-createDate" type="text" defaultValue={compliance.createDate} readOnly/>
                                
                            </div>
                        </div>
                        <div>
                            <label htmlFor="compliance-discussion">Agent discussion summary</label>
                            <textarea id="compliance-discussion" defaultValue={compliance.agentDiscussionSummary} rows={3} onChange={handleAgentDiscussion}/>
                        </div>
                        <div>
                            <label htmlFor="compliance-closure">Closure reason</label>
                            <input id="compliance-closure" type="text" defaultValue={compliance.closureReason} onChange={handleClosureReason}/>
                        </div>
                    </div>
                    <div className="compliance-form-btns">
                        <button type="submit" className="compliance-form-btn-submit" disabled={disabled}>Save</button>
                        <button type="reset" onClick={handleCloseModal} className="compliance-form-btn-reset">Close</button>
                    </div>
                </form> 
            </div>
        </div>
    )

}


export default ComplianceModal;